import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const InfographicDesign = () => (
  <Layout>
    <SEO
      title="How to design your own infographic"
      description="6 pro tips for designing your own infographics. Inform leads about your business quickly and expand on your mission statement"
    />
    <h1>How to Design Your Own Infographic</h1>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/7Cc2wyr.png"
        alt="6 pro tips for designing your own infographics"
      />
    </div>

    <h2>6 Pro Tips For Designing Your Own Infographics to Get More Leads</h2>
    <p>
    The design of your infographic is very important. When people see it and take the time to take it in, it should leave them happy, informed and more knowledgeable about you and your business.
    </p>
    <p>Unfortunately, this isn’t always the case and if you do it wrong, it can leave them disappointed and even betrayed. Betrayed sounds a bit extreme but some people will be very knowledgeable in your field and if you haven’t taken the time to find information from good sources and present it in an honest way, they will see right through it.</p>
    <p>I’ve spoken about how you want to use data that supports your arguments to sell your business but you should always be fair and not deceptive.</p>
    <p>Infographics can be a great way to gain supporters quickly and also lose them just as fast. Here’s 6 ways to make your best infographic.</p>

    <h2>Expand on Your Elevator Pitch</h2>
    <p>The great thing about infographics is that they can be about anything you want. They can be information heavy, meant for people who want to spend a lot of time on it. Or they can be light and meant for a quick scan to get a point across. The idea is that you can choose what you want to say.</p>
    <p>One of the best uses of an infographic is to expand on you elevator pitch or the mission statement of your business.</p>
    <p>You could take your audience through your services and how you achieve your goals. Why they should choose you over the competition. It can be like a landing page that isn’t on your site. One that other people share for you. Kind of like an internet leaflet or poster.</p>

    <h2>Reference Proper Sources</h2>
    <p>You will probably be using information directly from your own businesses. For example how you are different to the competition and what you do to benefit your customer. The numbers could be how many people you’ve helped or sales figures. In this case you are your own reference and source of information. People will have to trust you are telling the truth.</p>
    <p>If you are using information from external sources, you need to make sure that they are referenced properly and from credible places. Don’t use information just because it suits you. Check other sources to see if if lines up and makes sense. The more serious members of your audience will do their research and spot any issues. Reference like you are back in school.</p>

    <h2>Keep Within Your Brand Visuals</h2>
    <p>When the infographic has been shared and no longer just on your site. You want people to be able to recognise your brand easily. The branding, colours, graphics etc to reflect your business as much as possible.</p>
    <p>Infographics are a creative piece of graphic design so you can be flexible in some places but you want there to be a strong link to your brand. This way, when people who know your brand see the work, they will know it’s you. On the other hand, when your new audience sees the infographic first and then your site, you want them to be confident that it was in fact you who made it.</p>
    <p>There’s the added benefit of great branding and consistency across your material helps make it more difficult for others to claim it as their own work.</p>

    <h2>Go Through Iterations</h2>
    <p>No matter how good you think the first version is, go over it again and see how it can be improved. If you like to view things on screen, print it off. I’ve always found it amazing how I can spot things so much easier when they are on paper in front of me, especially graphics. You can mark them up by hand and scan them or take a picture to get it back onto the computer when you need to.</p>
    <p>Show your team and ask them what they think. Does it convey your message properly? Does it make sense? Then give it to a non-technical person, maybe a family member or friend and ask them what they think. Let them go through it themselves without prompting and see if they pick up everything you wanted them to. Make logical changes as you need to.</p>
    <p>Of course, not everyone is going to see it the same way but you want to know that at least there’s a good chance it will do the job it was intended to do. This leads neatly into my next point…</p>

    <h2>Show as Many Eyes as You Can</h2>
    <p>As I mentioned in my <Link to="/infographics-for-seo/" style={{ textDecoration: "none" }}>
        infographics for SEO
    </Link> article, once they are out there, there’s no taking them back. So take the time to review them properly. You can reword an article or fix some spelling mistakes in a post but you can’t do that with an infographic.</p>
    <p>Show professionalism by making sure everything is done properly with no silly mistakes. If there are mistakes, it will make you cringe every time you see it out there.</p>

    <h2>Present a Balanced View</h2>
    <p>This one is optional. People like to see flaws. If you always come off as perfect 100% of the time, they will start to doubt if it is true and eventually think you are lying to them. We all know that anyone who has found success has had their fair share of failure so don’t be afraid to share them.</p>
    <p>If some of your metrics have dropped or you have made mistakes, find a way to include them and somehow turn them into a positive. Say how you missed a target last year then smashed it this year and how you plan to do better next year. Your audience will love you more for it because you will be more relatable.</p>
    <p>Obviously, be careful with what you share and you want the overall message to be a positive one that gives off confidence.</p>

    <h2>Ready to Get Started?</h2>
    <p>With these tips in mind, you can get started on the actual  data collection and graphic design of your infographic. It can sound like a big job but if you break it down in small steps, it should be a smooth process.</p>
    <p>If you would prefer to <Link to="/outsourcing-infographics/" style={{ textDecoration: "none"}}>hire a graphic designer</Link> or even an infographic design specialist, you are in the right place. Get in touch through the <Link to="/about/" style={{ textDecoration: "none" }}>
    about page{" "} 
    </Link>to learn more.</p>

    {/* <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link> */}


    <Link to="/infographics/" style={{ textDecoration: "none" }}>
      Go back to the infographics homepage
    </Link>
  </Layout>
)

export default InfographicDesign
